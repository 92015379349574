import { useLayoutEffect } from 'react';

const useLockBodyScroll = (delay = 0) => {
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    setTimeout(() => {
      // Prevent scrolling on mount
      document.body.style.overflow = 'hidden';
    }, delay);
    // Re-enable scrolling when component unmounts
    return () => (document.body.style.overflow = originalStyle);
  }, []); // Empty array ensures effect is only run on mount and unmount
};

export default useLockBodyScroll;
