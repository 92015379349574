import React, { useState, useCallback } from 'react';
import ReactModal from 'react-modal';

import useEventListener from '~/hooks/components/useEventListener';
import DesktopNav from '~/components/Nav/Desktop';
// import MobileNav from '~/components/Nav/Mobile';
import WelcomePrompt from '~/components/Prompts/Welcome';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(255,255,255,0)',
    border: 'none',
    borderRadius: 0,
    padding: '30px',
    zIndex: 99,
  },
};

ReactModal.setAppElement('#portal');

const thatWindow = typeof window !== 'undefined' ? window : null;

const Layout = ({ children }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalHasOpened, setModalHasOpened] = useState(false);

  const scrollHandler = useCallback(() => {
    if (!modalHasOpened) {
      setTimeout(() => {
        setModalIsOpen(true);
      }, 750);
    }
  }, [setModalIsOpen, modalHasOpened]);

  const handleModalClose = () => {
    setModalIsOpen(false);
    setModalHasOpened(true);
  };

  useEventListener('scroll', scrollHandler, thatWindow);

  return (
    <>
      <DesktopNav />
      {/* <MobileNav /> */}
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={handleModalClose}
        style={customStyles}
      >
        <WelcomePrompt handleModalClose={handleModalClose} />
      </ReactModal>
      <main>{children}</main>
    </>
  );
};

export default Layout;
