/** @jsx jsx */
import React, { useEffect } from 'react';
import { Box, jsx } from 'theme-ui';
import { Link } from 'gatsby';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import InstagramIcon from '~/assets/images/instagram-icon.svg';
import AugustIcon from '~/assets/images/August-A.svg';
import FacebookIcon from '~/assets/images/facebook.svg';

const DesktopNav = () => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger);
      gsap.core.globals('ScrollTrigger', ScrollTrigger);
    }

    gsap.to('#nav', {
      x: 50,
      duration: 0.5,
      scrollTrigger: {
        trigger: '#august-is',
        start: 'top 25%',
      },
    });
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: 'alizarinCrimson',
        zIndex: 5,
        display: 'flex',
        position: 'fixed',
        padding: '0 20px',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '50px',
        top: ['60px', '60px', '128px'],
        left: 0,
        width: '286px',
        fontSize: '15px',
        lineHeight: '18px',
        transform: 'rotate(-270deg)',
        transformOrigin: 'top left',
        ul: {
          position: 'absolute',
          listStyle: 'none',
          margin: 0,
          marginBottom: '-3px',
          height: '100%',
          textAlign: 'center',
          whiteSpace: 'nowrap',
          display: 'flex',
          alignItems: 'center',
          transform: 'rotate(180deg)',
          li: {
            display: 'inline-block',
            color: 'chatelle',
            '&:first-of-type': {
              marginRight: '5px',
            },
            '.inline': {
              display: 'flex',
              alignItems: 'center',
              '& > a': {
                marginLeft: '15px',
              },
              svg: {
                transform: 'rotate(90deg)',
              },
            },
            a: {
              display: 'inline-flex',
            },
          },
        },
      }}
      as="nav"
      id="nav"
    >
      <ul>
        {/* <li>
        <Link to="/shop">Shop</Link>
      </li> */}
        <li>
          <a
            href="https://links.genevachat.com/invite/0f355b9e-b373-43ac-a8df-b843b3d6c433"
            target="_blank"
            rel="noreferrer"
          >
            Join Inner Cycle
          </a>
        </li>
        <li>
          <div className="inline">
            <a
              href="https://www.facebook.com/itsaugustco"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon />
            </a>
            <a
              href="https://www.instagram.com/itsaugust/"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon />
            </a>
            <Link to="/">
              <AugustIcon className="august-icon" />
            </Link>
          </div>
        </li>
        <li />
      </ul>
    </Box>
  );
};

export default DesktopNav;
