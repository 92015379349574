/** @jsx jsx */
import React, { useState } from 'react';
import { Box, Flex, Text, Input, Button, IconButton, jsx } from 'theme-ui';
import { analytics } from '@arfabrands/gatsby-theme-assembly';

import useLockBodyScroll from '~/hooks/components/useLockBodyScroll';
import CtaArrowModal from '~/assets/images/icons/ArrowModal.svg';
import AugustA from '~/assets/images/logos/August-A-Crimson.svg';
import CloseIcon from '~/assets/images/icons/X.svg';

const WelcomePrompt = ({ handleModalClose }) => {
  useLockBodyScroll(500);
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { trackEmailCaptured } = analytics;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      email,
      placement: {
        page: 'merch',
        component: 'inner-cycle-form',
        website: window.location.hostname,
      },
    };
    setSubmitting(true);

    try {
      await trackEmailCaptured(payload);
      await fetch('/.netlify/functions/captureEmail', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      setSubmitted(true);
      setTimeout(() => {
        handleModalClose();
      }, 1750);
    } catch (err) {
      console.log(err);
    }
    setSubmitting(false);
  };

  return (
    <>
      <IconButton
        name="close-modal"
        onClick={handleModalClose}
        sx={{
          cursor: 'pointer',
          position: 'absolute',
          top: ['-5px', '-5px', 0],
          right: ['30px', '30px', 0],
          zIndex: 100,
          '& > svg': {
            width: '29px',
            height: '29px',
          },
        }}
      >
        <CloseIcon />
      </IconButton>
      <Flex
        sx={{
          position: 'relative',
          background: [
            'url(/images/Mobile-BG_Modal.svg)',
            'url(/images/Mobile-BG_Modal.svg)',
            'url(/images/Tablet-BG_Modal.svg)',
            'url(/images/Desktop-BG_Modal.svg)',
          ],
          flexDirection: 'column',
          alignItems: 'center',
          width: ['345px', '345px', '500px', '720px'],
          height: ['387px', '387px', '360px', '540px'],
        }}
      >
        <Box
          sx={{
            marginTop: ['70px', null, '82px', '110px'],
            '& > svg': {
              width: ['37.74px', null, null, '61px'],
              height: ['34px', null, null, '55px'],
            },
          }}
        >
          <AugustA />
        </Box>
        <Box
          sx={{
            color: 'alizarinCrimson',
            textTransform: 'uppercase',
            fontStyle: 'italic',
            textAlign: 'center',
            marginTop: ['21px', null, '16px'],
          }}
        >
          <Text
            sx={{
              fontSize: ['16px', null, '19px', '30px'],
              lineHeight: ['19.2px', null, '22.8px', '36px'],
              letterSpacing: '7px',
            }}
          >
            join our
          </Text>
          <Text
            sx={{
              fontSize: ['28px', null, '32px', '48px'],
              lineHeight: ['33.6px', null, '38.4px', '57.6px'],
              letterSpacing: '5px',
              fontWeight: 700,
            }}
          >
            inner cycle
          </Text>
        </Box>
        {submitted ? (
          <Flex
            sx={{
              width: ['241px', null, '360px', '530px'],
              flexDirection: ['column', null, 'row'],
              alignItems: 'center',
              justifyContent: 'center',
              height: ['50px'],
              marginTop: ['56px'],
              color: 'alizarinCrimson',
              fontWeight: 300,
              fontSize: [null, '18px', '26px'],
              lineHeight: [null, '21.42px', '28.64px'],
              letterSpacing: ['-5%'],
              '& > span': {
                display: ['none', 'block'],
              },
            }}
          >
            <Text>
              Got it! Stay tuned for <span>&nbsp;</span>
            </Text>
            <Text>Innercyle updates.</Text>
          </Flex>
        ) : (
          <Box
            sx={{
              width: ['241px', null, '360px', '530px'],
              height: ['50px'],
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '2px solid #E3232A',
              marginTop: ['75px', null, '30px', '70px'],
            }}
            as="form"
            onSubmit={handleSubmit}
          >
            <Input
              disabled={submitting}
              required
              type="email"
              name="email"
              placeholder="YOUR EMAIL"
              onChange={(e) => setEmail(e.target.value.toLocaleUpperCase())}
              value={email}
              sx={{
                color: 'alizarinCrimson',
                fontWeight: 300,
                fontSize: ['17px', null, null, '36px'],
                lineHeight: ['18.75px', null, null, '39.64px'],
                letterSpacing: ['-5%'],
                border: 'none',
                '&:focus': {
                  outline: 'none',
                },
                '&::-webkit-input-placeholder': {
                  fontWeight: 300,
                  color: 'alizarinCrimson',
                  fontSize: ['17px', null, null, '36px'],
                  lineHeight: ['18.75px', null, null, '39.64px'],
                  letterSpacing: ['-5%'],
                },
                '&::-ms-input-placeholder': {
                  fontWeight: 300,
                  color: 'alizarinCrimson',
                  fontSize: ['17px', null, null, '36px'],
                  lineHeight: ['18.75px', null, null, '39.64px'],
                  letterSpacing: ['-5%'],
                },
                '&::-moz-input-placeholder': {
                  fontWeight: 300,
                  color: 'alizarinCrimson',
                  fontSize: ['17px', null, null, '36px'],
                  lineHeight: ['18.75px', null, null, '39.64px'],
                  letterSpacing: ['-5%'],
                },
                '&::-placeholder': {
                  fontWeight: 300,
                  color: 'alizarinCrimson',
                  fontSize: ['17px', null, null, '36px'],
                  lineHeight: ['18.75px', null, null, '39.64px'],
                  letterSpacing: ['-5%'],
                },
              }}
            />
            <IconButton
              p={0}
              m={0}
              sx={{
                alignItems: 'center',
                backgroundColor: 'inherit',
                color: 'alizarinCrimson',
                cursor: 'pointer',
                boder: 'none',
                fontSize: ['24px', null, '42px'],
                fontWeight: 300,
                lineHeight: ['24px', null, '46.24px'],
                letterSpacing: ['-.5px'],
                outline: 'none',
                '&:hover': {
                  backgroundColor: 'inherit',
                },
              }}
              type="submit"
            >
              <CtaArrowModal />
            </IconButton>
          </Box>
        )}
      </Flex>
    </>
  );
};

export default WelcomePrompt;
